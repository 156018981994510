.payments-due-dates-widget {
  height: 180px;
  position: relative;
}

.payments-due-dates-widget-loader {
  height: 180px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.card-body-simple {
  height: 180px !important;
}

#analyticsView-payments-due-dates {
  box-shadow: none !important;
  margin: 10px 0;
}

#analyticsView-payments-due-dates-status {
  margin-top: 0;
  margin-bottom: 0;
}

#analyticsView-top-10-customers {
  margin-top: 0;
}

div.chart-kpi.analyticsView-payments-due-dates-status, div.chart-kpi.analyticsView-top-10-customers {
  height: 238px !important;
}

.payments-due-dates-status-widget, .top-10-customers-widget {
  height: auto;
  position: relative;
}

.payments-due-dates-status-widget-loader, .top-10-customers-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-payments-due-dates-status>div>.card>.card-body, #analyticsView-top-10-customers>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-payments-due-dates-status>div>.card>.card-body {
  margin-bottom: 0;
}