.costs-widget {
  height: 180px;
  position: relative;
}

.costs-widget-loader {
  height: 180px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.card-body-simple {
  height: 180px !important;
}

#analyticsView-costs {
  box-shadow: none !important;
  margin: 10px 0;
}

#analyticsView-costs-yearly {
  margin-top: 0;
  margin-bottom: 0;
}

#analyticsView-costs-monthly {
  margin-top: 0;
}

div.chart-kpi.analyticsView-costs-yearly, div.chart-kpi.analyticsView-costs-monthly {
  height: 238px !important;
}

.costs-yearly-widget, .costs-monthly-widget {
  height: auto;
  position: relative;
}

.costs-yearly-widget-loader, .costs-monthly-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-costs-yearly>div>.card>.card-body, #analyticsView-costs-monthly>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-costs-yearly>div>.card>.card-body {
  margin-bottom: 0;
}