.flex {
    display: flex;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.activate-ts-analytics {
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    height: 335px;
}

.auto-margin {
    margin-top: auto;
}

.activate-ts-analytics-container {
    display: flex;
    flex-direction: column;
    padding: 24px;
    height: 335px;
    align-content: space-around;
}

.activate-ts-analytics-title {
    color: #005075;
    font-size: 20px;
    font-family: "Cairo";
    font-weight: 600;
    margin: 0;
}

.activate-ts-analytics-text {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.25px;
    text-align: left;
}

.scrollable {
    overflow-y: auto;
    margin-bottom: 10px;
}

.scrollable::-webkit-scrollbar {
    width: 10px;
    display: block !important;
}

.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.scrollable::-webkit-scrollbar-thumb {
    background: #888;
}

.scrollable::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.activate-ts-analytics-btn {
    display: inline-block;
    padding: 8px 15px;
    cursor: pointer;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.2px;
}

.btn-1 {
    color: #FFFFFF;
    border-radius: 2px;
    background: linear-gradient(342.97deg, #00C3EA -37.16%, #0090D1 93.4%);
    text-shadow: 1px 1px 0px rgba(21, 41, 53, 0.24);
}

.btn-2 {
    color: #0090D1;
    margin-left: 15px;
}

.btn-3 {
    border: 1px solid #0090D1;
    border-radius: 2px;
    margin-right: 15px;
    color: #0090D1;
}

.activate-ts-analytics-img {
    width: 249px;
    margin-right: 79px;
}

.menu-img {
    width: 200px;
}

.activate-ts-analytics-modal {
    padding: 15px;
    background: #FFFFFF;
    box-shadow: 0px 31px 41px rgba(33, 43, 54, 0.2), 0px 2px 16px rgba(33, 43, 54, 0.08);
    border-radius: 6px;
    position: absolute;
    top: 15px;
    width: 520px;
    height: 300px;
    left: calc(50% - 230px);
}

.modal-text {
    margin-top: 0;
}

.modal-title {
    margin-bottom: 0px;
}

.more-kpi-modal-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: auto;
}

.modal-content {
    overflow-y: auto;
    height: 100%;
    margin-bottom: 5px;
}

.modal-content::-webkit-scrollbar {
    width: 10px;
    display: block !important;
}

.modal-content::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.modal-content::-webkit-scrollbar-thumb {
    background: #888;
}

.modal-content::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.activate-ts-analytics-modal-close {
    position: absolute!important;
    cursor: pointer;
    right: 20px;
    top: 20px;
    color: #454f5b;
    font-size: 20px;
}

.modal-loading {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-success {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-error {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.fit-content {
    width: fit-content;
}

.display-contents {
    display: contents;
}

.btn-disabled {
    cursor: default;
    opacity: 0.5;
}