div.chart-kpi.analyticsView-cash-flow {
  height: 300px !important;
  padding-bottom: 20px;
}

.cash-flow-widget {
  height: auto;
  position: relative;
}

.cash-flow-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-cash-flow {
  box-shadow: none !important;
  margin: 10px 0;
}

#analyticsView-cash-flow>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-cash-flow-in {
  margin-top: 0;
  margin-bottom: 0;
}

#analyticsView-cash-flow-out {
  margin-top: 0;
}

div.chart-kpi.analyticsView-cash-flow-in, div.chart-kpi.analyticsView-cash-flow-out {
  height: 238px !important;
}

.cash-flow-in-widget, .cash-flow-out-widget {
  height: auto;
  position: relative;
}

.cash-flow-in-widget-loader, .cash-flow-out-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-cash-flow-in>div>.card>.card-body, #analyticsView-cash-flow-out>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-cash-flow-in>div>.card>.card-body, #analyticsView-cash-flow, #analyticsView-cash-flow>div>.card>.card-body {
  margin-bottom: 0;
}