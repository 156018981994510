.revenue-widget {
  height: 180px;
  position: relative;
}

.revenue-widget-loader {
  height: 180px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.card-body-simple {
  height: 180px !important;
}

#analyticsView-revenue {
  box-shadow: none !important;
  margin: 10px 0;
}

#analyticsView-revenue-yearly {
  margin-top: 0;
  margin-bottom: 0;
}

#analyticsView-revenue-monthly {
  margin-top: 0;
}

div.chart-kpi.analyticsView-revenue-yearly, div.chart-kpi.analyticsView-revenue-monthly {
  height: 238px !important;
}

.revenue-yearly-widget, .revenue-monthly-widget {
  height: auto;
  position: relative;
}

.revenue-yearly-widget-loader, .revenue-monthly-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-revenue-yearly>div>.card>.card-body, #analyticsView-revenue-monthly>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-revenue-yearly>div>.card>.card-body {
  margin-bottom: 0;
}