.total-sales-unpaid-widget {
  height: 180px;
  position: relative;
}

.total-sales-unpaid-widget-loader {
  height: 180px;
  display: flex;
  justify-content: center;
  background-color: white;
}

.card-body-simple {
  height: 180px !important;
}

#analyticsView-total-sales-unpaid {
  box-shadow: none !important;
  margin: 10px 0;
}

#analyticsView-total-sales-unpaid-year {
  margin-top: 0;
  margin-bottom: 0;
}

#analyticsView-total-sales-unpaid-period {
  margin-top: 0;
}

div.chart-kpi.analyticsView-total-sales-unpaid-year, div.chart-kpi.analyticsView-total-sales-unpaid-period {
  height: 238px !important;
}

.total-sales-unpaid-year-widget, .total-sales-unpaid-period-widget {
  height: auto;
  position: relative;
}

.total-sales-unpaid-year-widget-loader, .total-sales-unpaid-period-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-total-sales-unpaid-year>div>.card>.card-body, #analyticsView-total-sales-unpaid-period>div>.card>.card-body {
  height: auto !important;
}

#analyticsView-total-sales-unpaid-year>div>.card>.card-body {
  margin-bottom: 0;
}