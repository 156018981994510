.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px)
  }
  100% {
    transform: translateY(0px)
  }
}

.spinner {
  width: 100%;
}

.mt-20 {
  margin-top: 20px;
}

.ml-15 {
  margin-left: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.box {
  border: 1px solid #EDEDED;
  border-radius: 5px;
  padding: 10px;
}

.mw-400 {
  max-width: 400px;
}

.mw-200 {
  max-width: 200px;
}

.mh-400 {
  max-height: 400px;
}

.mw-50p {
  max-width: 50%;
}

.fw-b {
  font-weight: bold;
}

.mb-20 {
  margin-bottom: 20px;
}

.loader-20 {
  max-height: 20px !important;
  max-width: 20px !important;
}

.display-b {
  display: block !important;
}

.ta-r {
  text-align: right;
}

.clickable {
  cursor: pointer;
}

.w-80 {
  width: 80% !important;
}

.no-link-style:link, .no-link-style:visited, .no-link-style:hover, .no-link-style:active {
  text-decoration: none;
}

.tooltip-position {
  position: absolute !important;
  left: 20px;
  bottom: 10px;
}

.tooltip-icon {
  color: #152935;
  margin: 3px;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltip-text {
  visibility: hidden;
  opacity: 0;
  width: 120px;
  background-color: #152935;
  color: #fff;
  text-align: center;
  border-radius: 0px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -50% !important;
  left: 420% !important;
  height: fit-content;
}

.container-shadow {
  transition: all .3s ease-in-out;
  box-shadow: 0 2px 8px 0 rgb(21 41 53 / 32%);
}

.expand-widget {
  display: flex;
  flex-direction: row-reverse;
  background-color: #f2f5f8;
}

.expand-widget-button {
  display: flex;
  cursor: pointer;
  padding: 10px;
  color: #0090d1;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: .25px;
}

.simple-kpi-head-title {
  font-family: 'Cairo';
  font-weight: 600 !important;
  font-size: 20px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simple-kpi-value-row strong {
  font-size: 30px !important;
}

.anlwdg-card-container * .card-header {
  padding: 24px 24px 0 24px !important;
}

.anlwdg-card-container * .card-body {
  margin: 5px 0;
}

.anlwdg-card-container * .card-body-simple {
  padding: 24px 24px 32px 24px !important;
}

.amount-decimal-part {
  font-size: 24px !important;
}

.anlwdg-card-container * .simple-kpi-value-left {
  padding: 0 !important;
}

.freccia-kpi img {
  height: 26px !important;
}

.subtitle {
  position: absolute !important;
  font-family: Roboto, sans-serif;
  color: #9da6ad;
  font-size: 14px;
  font-weight: 400;
  height: 32px;
  letter-spacing: .25px;
  line-height: 24px;
}

.current-year {
  left: 24px;
  bottom: 30px;
}

.previous-year-comparison {
  right: 24px;
  bottom: 30px;
}

.widget-home {
  height: 200px;
  display: flex;
  justify-content: center;
}

.popup-info {
  position: absolute !important;
  cursor: pointer;
  right: 20px;
  top: 20px;
  color: #005075;
}

.hidden-scrollbar {
  overflow-y: scroll;
  /* Firefox */
  scrollbar-width: none;
  /* Internet Explorer 10+ */
  -ms-overflow-style: none;
}

.hidden-scrollbar::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar {
  display: none;
}

.data-error-container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  background-color: white;
}

.data-error-section {
  text-align: center;
  height: fit-content;
}

.data-error-text {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.right-30 {
  right: 30px !important;
}

.popup-info-content {
  padding: 20px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.popup-info-scroll {
  overflow-y: auto;
  max-height: 400px;
}

.popup-info-scroll::-webkit-scrollbar {
  width: 10px;
  display: block !important;
}

.popup-info-scroll::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.popup-info-scroll::-webkit-scrollbar-thumb {
  background: #888;
}

.popup-info-scroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.popup-info-title {
  margin-top: 10px !important;
  font-family: "Cairo";
  font-weight: 600 !important;
  font-size: 20px !important;
}

.loading-section {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}