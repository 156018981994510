div.chart-kpi.analyticsView-costs-ddts-purchase-orders {
  height: 270px !important;
  padding-bottom: 20px;
}

.purchase-orders-widget {
  height: auto;
  position: relative;
}

.purchase-orders-widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-costs-ddts-purchase-orders>div>.card>.card-body {
  height: auto !important;
}