div.chart-kpi.analyticsView-revenues-ddts-sales-orders {
  height: 270px !important;
  padding-bottom: 20px;
}

.widget {
  height: auto;
  position: relative;
}

.widget-loader {
  height: 300px;
  display: flex;
  justify-content: center;
  background-color: white;
}

#analyticsView-revenues-ddts-sales-orders>div>.card>.card-body {
  height: auto !important;
}